import actionTypes from './conditions.actionTypes';
import { Condition, ConditionData, ConditionsDayReport } from './Condition';

export const fetchConditionsList = ({
    teamId,
    organizationId,
    fromDate,
    toDate,
}: {
    organizationId: string;
    teamId: string;
    fromDate: Date;
    toDate: Date;
}) => ({
    type: actionTypes.REQUEST_CONDITIONS_LIST,
    organizationId,
    teamId,
    fromDate,
    toDate,
});

export const fetchConditionsForEvent = ({
    teamId,
    organizationId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    eventId: string;
}) => ({
    type: actionTypes.REQUEST_CONDITIONS_FOR_EVENT,
    organizationId,
    teamId,
    eventId,
});

export const requestConditionData = ({
    teamId,
    organizationId,
    conditionId,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
}) => ({
    type: actionTypes.REQUEST_CONDITION_DATA,
    organizationId,
    teamId,
    conditionId,
});

export const requestPlayerEventCondition = ({
    teamId,
    organizationId,
    playerId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    playerId: string;
    eventId: string;
}) => ({
    type: actionTypes.REQUEST_PLAYER_EVENT_CONDITION,
    organizationId,
    teamId,
    playerId,
    eventId,
});

export const conditionsListReceived = ({ conditionsList }: { conditionsList: Condition[] }) => ({
    type: actionTypes.CONDITIONS_LIST_RECEIVED,
    conditionsList,
});

export const conditionDataReceived = ({ condition }: { condition: Condition }) => ({
    type: actionTypes.CONDITION_DATA_RECEIVED,
    condition,
});

export const addConditionData = ({
    organizationId,
    teamId,
    conditionData,
    onSuccess,
}: {
    conditionData: ConditionData;
    teamId: string;
    organizationId: string;
    onSuccess?: (conditionId: string) => void;
}) => ({
    type: actionTypes.ADD_CONDITION_DATA,
    organizationId,
    teamId,
    conditionData,
    onSuccess,
});

export const editConditionData = ({
    organizationId,
    teamId,
    conditionId,
    conditionData,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
    conditionData: ConditionData;
}) => ({
    type: actionTypes.EDIT_CONDITION_DATA,
    organizationId,
    teamId,
    conditionId,
    conditionData,
});

export const deleteCondition = ({
    organizationId,
    teamId,
    conditionId,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
}) => ({
    type: actionTypes.DELETE_CONDITION,
    organizationId,
    teamId,
    conditionId,
});

export const removeCondition = ({
    organizationId,
    teamId,
    conditionId,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
}) => ({
    type: actionTypes.REMOVE_CONDITION,
    organizationId,
    teamId,
    conditionId,
});

export const requestConditionsDayReport = ({
    organizationId,
    teamId,
    conditionsDayReportId,
}: {
    organizationId: string;
    teamId: string;
    conditionsDayReportId: string;
}) => ({
    type: actionTypes.REQUEST_CONDITIONS_DAY_REPORT,
    organizationId,
    teamId,
    conditionsDayReportId,
});

export const conditionsDayReportReceived = ({
    conditionsDayReport,
}: {
    conditionsDayReport: ConditionsDayReport;
}) => ({
    type: actionTypes.CONDITIONS_DAY_REPORT_RECEIVED,
    conditionsDayReport,
});
