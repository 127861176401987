import actionTypes from './clubLocations.actionTypes';
import { ClubLocation, ClubLocationData, LocationTypesMetadata } from './ClubLocations';

export const createClubLocationRequest = ({
    clubLocationData,
    organizationId,
    onSuccess,
    onFail,
}: {
    clubLocationData: ClubLocationData;
    organizationId: string;
    onSuccess: (clubLocationId: string) => void;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.CREATE_CLUB_LOCATION_REQUEST,
    clubLocationData,
    organizationId,
    onSuccess,
    onFail,
});

export const updateClubLocationRequest = ({
    organizationId,
    clubLocation,
    clubLocationId,
    onFail,
}: {
    organizationId: string;
    clubLocation: ClubLocation;
    clubLocationId: string;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_CLUB_LOCATION_REQUEST,
    organizationId,
    clubLocation,
    clubLocationId,
    onFail,
});

export const clubLocationsListRequest = ({
    organizationId,
    onFail,
}: {
    organizationId: string;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.CLUB_LOCATIONS_LIST_REQUEST,
    organizationId,
    onFail,
});

export const clubLocationsListReceived = ({
    clubLocationsList,
}: {
    clubLocationsList: ClubLocation[];
}) => ({
    type: actionTypes.CLUB_LOCATIONS_LIST_RECEIVED,
    clubLocationsList,
});

export const clubLocationRequest = ({
    organizationId,
    onFail,
}: {
    organizationId: string;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.CLUB_LOCATION_REQUEST,
    organizationId,
    onFail,
});

export const clubLocationReceived = ({
    clubLocation,
}: {
    clubLocation: ClubLocation;
    clubLocationId: string;
}) => ({
    type: actionTypes.CLUB_LOCATION_RECEIVED,
    clubLocation,
});

export const clubLocationDeleteRequest = ({
    organizationId,
    clubLocationId,
    onSuccess,
    onFail,
}: {
    organizationId: string;
    clubLocationId: string;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.CLUB_LOCATION_DELETE_REQUEST,
    organizationId,
    clubLocationId,
    onSuccess,
    onFail,
});

export const removeClubLocation = ({ clubLocationId }: { clubLocationId: string }) => ({
    type: actionTypes.CLUB_LOCATION_REMOVE,
    clubLocationId,
});

export const locationTypesDataReceived = ({
    locationTypesMetadata,
    organizationId,
}: {
    locationTypesMetadata: LocationTypesMetadata;
    organizationId: string;
}) => ({
    type: actionTypes.CLUB_LOCATION_TYPES_RECEIVED,
    locationTypesMetadata,
    organizationId,
});

export const locationTypesDataRequest = ({ organizationId }: { organizationId: string }) => ({
    type: actionTypes.CLUB_LOCATION_TYPES_REQUEST,
    organizationId,
});

export const locationTypesDataUpdate = ({
    locationTypesMetadata,
    organizationId,
}: {
    locationTypesMetadata: LocationTypesMetadata;
    organizationId: string;
}) => ({
    type: actionTypes.CLUB_LOCATION_TYPES_UPDATE,
    locationTypesMetadata,
    organizationId,
});
