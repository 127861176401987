import { EventTypes } from 'entities/events/Event';
import { SessionTypes } from 'entities/events/Session';

export enum ConditionTypes {
    wellness = 'wellness',
    load = 'load',
}

export enum WellnessParams {
    generalCondition = 'generalCondition',
    sleepQuality = 'sleepQuality',
    generalMuscleSoreness = 'generalMuscleSoreness',
    stressLevel = 'stressLevel',
    mood = 'mood',
    fatigue = 'fatigue',
}

export const wellnessParamsList = Object.values(WellnessParams);
export const eventsParamsList = ['loadLevel', 'wellnessBeforeEvent'] as (
    | 'loadLevel'
    | 'wellnessBeforeEvent'
)[];

export type ConditionsParams = {
    date?: Date;
    // TODO: check if needed
    playerId?: string;
    conditionType: ConditionTypes;
    details: {
        param?: WellnessParams;
        teamId?: string;
        eventId?: string;
        eventType?: EventTypes.session | EventTypes.game;
        startTime?: Date;
        title?: string;
        sessionType?: SessionTypes;
        opponentName?: string;
        /*
         * This value is by player only during the RPE survey.
         * It is being used if load time is not set by the coach for session or game.
         * Load time for player in game or session is a primary option. If it is not set, then this value is used.
         */
        loadTime?: number;
    };
};

export type ConditionData = ConditionsParams & {
    playerId: string;
    teamId: string;
    /* Active player's teams at the moment the condition created */
    referencedTeams: string[];
    value: number;
    maxValue: number;
};

export type Condition = ConditionData & {
    id: string;
};

export type ConditionsSettings = Record<WellnessParams, boolean> & {
    conditionSize: number;
    wellnessHalves: boolean;
    loadSize: number;
    loadHalves: boolean;
    loadLevel: boolean;
    wellnessBeforeEvent: boolean;
    shouldAskLoadTime: boolean;
    shouldAskSessionLoadType: boolean;
};

export type ConditionsSchedule = {
    wellness: {
        active: boolean;
        /* UTC time to create task in format 'HH:mm' */
        utcTime: string | null;
    };
    load: {
        active: boolean;
    };
};

export const defaultConditionsSchedule = {
    wellness: {
        active: false,
        utcTime: null,
    },
    load: {
        active: false,
    },
};

export type ConditionsDayReport = {
    id: string;
    date: Date;
    players: Record<string, Record<string, ConditionData>>;
};
