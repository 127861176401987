import { GamesSettings } from 'entities/events/Game';
import { SessionsSettings } from 'entities/events/Session';
import { ExternalLink } from 'common/external-links/ExternalLink';
import { CommonLabel } from 'common/components/CommonLabelsForm/CommonLabels';
import { ConditionsSchedule, ConditionsSettings } from 'entities/conditions/Condition';

export enum TeamStatus {
    deleted = 'deleted',
    active = 'active',
    archived = 'archived',
}

export type GameTypesMetadata = {
    gameTypes: CommonLabel[];
};

export type TeamData = {
    name: string;
    creatorId: string;
    settings: {
        conditions: ConditionsSettings;
        conditionsSchedule: ConditionsSchedule;
        sessions: SessionsSettings;
        games: GamesSettings;
    };
    deletedDate: Date | null;
    archivedDate: Date | null;
    lastActivity: Date;
    createDate: Date;
    links?: ExternalLink[];
    status: TeamStatus;
    lastUpdateAuthorUid: string;
};

type Team = TeamData & {
    id: string;
    organizationId: string;
};

export default Team;
