import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as toastrReducer } from 'react-redux-toastr';
// entities - reducers
import user from 'entities/users/users.reducer';
import access from 'entities/access/access.reducer';
import players from 'entities/players/players.reducer';
import agents from 'entities/agents/agents.reducer';
import agencies from 'entities/agencies/agencies.reducer';
import fitnessData from 'entities/fitness-data/fitnessData.reducer';
import clubLocations from 'entities/club-locations/clubLocations.reducer';
import events from 'entities/events/events.reducer';
import goals from 'entities/goals/goals.reducer';
import teams from 'entities/teams/teams.reducer';
import organizations from 'entities/organizations/organizations.reducer';
import methodologies from 'entities/methodologies/methodologies.reducer';
import invitations from 'entities/invitations/invitations.reducer';
import observations from 'entities/observations/observations.reducer';
import evaluations from 'entities/evaluations/evaluations.reducer';
import drills from 'entities/drills/drills.reducer';
import condition from 'entities/conditions/conditions.reducer';
import reports from 'entities/reports/reports.reducer';
import tracking from 'entities/tracking/tracking.reducer';
import episodes from 'entities/episodes/episodes.reducer';
import markers from 'entities/markers/markers.reducer';
import challenges from 'entities/challenges/challenges.reducer';
import solutions from 'entities/solutions/solutions.reducer';
import tasks from 'entities/tasks/tasks.reducer';
import userTasks from 'entities/user-tasks/userTasks.reducer';
import notifications from 'entities/notifications/notifications.reducer';
import scoutingLists from 'entities/scouting-lists/scoutingLists.reducer';
import cycles from 'entities/cycles/cycles.reducer';
import storageFiles from 'entities/storage-files/storageFiles.reducer';
import clubs from 'entities/clubs/clubs.reducer';
import leagues from 'entities/leagues/leagues.reducer';
import medicalRecords from 'entities/medical-records/medicalRecords.reducer';
import medicalTests from 'entities/medical-tests/medicalTests.reducer';
import injuries from 'entities/injuries/injuries.reducer';
// entities - sagas
import reportsProgress from 'entities/reports-progress/reportsProgress.reducer';
import emailsSagas from 'entities/emails/emails.sagas';
import scoutingListsSagas from 'entities/scouting-lists/scoutingLists.sagas';
import userSaga from 'entities/users/users.sagas';
import accessSagas from 'entities/access/access.sagas';
import playersSagas from 'entities/players/players.sagas';
import agentsSagas from 'entities/agents/agents.sagas';
import agenciesSagas from 'entities/agencies/agencies.sagas';
import eventsSaga from 'entities/events/events.sagas';
import goalsSagas from 'entities/goals/goals.sagas';
import methodologiesSaga from 'entities/methodologies/methodologies.sagas';
import organizationsSagas from 'entities/organizations/organizations.sagas';
import teamsSagas from 'entities/teams/teams.sagas';
import invitationsSagas from 'entities/invitations/invitations.sagas';
import observationsSagas from 'entities/observations/observations.sagas';
import evaluationsSagas from 'entities/evaluations/evaluations.sagas';
import drillsSaga from 'entities/drills/drills.sagas';
import conditionSagas from 'entities/conditions/conditions.sagas';
import episodesSaga from 'entities/episodes/episodes.sagas';
import trackingSaga from 'entities/tracking/tracking.sagas';
import markersSaga from 'entities/markers/markers.sagas';
import reportsSaga from 'entities/reports/reports.sagas';
import reportsProgressSaga from 'entities/reports-progress/reportsProgress.sagas';
import challengesSaga from 'entities/challenges/challenges.sagas';
import solutionsSaga from 'entities/solutions/solutions.sagas';
import tasksSaga from 'entities/tasks/tasks.sagas';
import userTasksSaga from 'entities/user-tasks/userTasks.sagas';
import notificationsSagas from 'entities/notifications/notifications.sagas';
import cyclesSagas from 'entities/cycles/cycles.sagas';
import storageFilesSagas from 'entities/storage-files/storageFiles.sagas';
import clubsSagas from 'entities/clubs/clubs.sagas';
import leaguesSagas from 'entities/leagues/leagues.sagas';
import medicalRecordsSagas from 'entities/medical-records/medicalRecords.sagas';
import medicalTestsSagas from 'entities/medical-tests/medicalTests.sagas';
import fitnessDataSagas from 'entities/fitness-data/fitnessData.sagas';
import injuriesSagas from 'entities/injuries/injuries.sagas';
import clubLocationsSagas from 'entities/club-locations/clubLocations.sagas';

// features - reducers
import calendar from 'features/calendar/calendar.reducer';
import auth from 'features/auth/auth.reducer';
import evaluationsPage from 'features/evaluation/evaluationPage.reducer';
import invitationPage from 'features/invitation/invitationPage.reducer';
import reportPage from 'features/report/reportPage.reducer';
import challengesPage from 'features/challenges/challengesPage.reducer';
import reportTagging from 'features/report-tagging/reportTaggingPage.reducer';
// features - sagas
import calendarSaga from 'features/calendar/calendar.sagas';
import invitationSagas from 'features/invitation/invitationPage.sagas';
import authSagas from 'features/auth/auth.sagas';
import sessionSaga from 'features/session/session.sagas';
import playerSagas from 'features/player/playerPage.sagas';
import evaluationPageSagas from 'features/evaluation/evaluationPage.sagas';
import reportSetupPageSagas from 'features/report-setup/reportSetupPage.sagas';
import reportTaggingPageSaga from 'features/report-tagging/reportTaggingPage.sagas';
import challengesPageSagas from 'features/challenges/challengesPage.sagas';
import reportPageSagas from 'features/report/reportPage.sagas';
import scoutingSagas from 'features/scouting/scouting.sagas';

// common
import appConfigSagas from 'common/config/config.sagas';
import config from 'common/config/config.reducer';
import { reducer as snackbar } from 'common/snackbar';

// @ts-ignore
const reducer = combineReducers({
    config,
    snackbar,
    toastr: toastrReducer,
    entities: combineReducers({
        players,
        goals,
        user,
        events,
        teams,
        agents,
        agencies,
        fitnessData,
        clubLocations,
        methodologies,
        organizations,
        invitations,
        access,
        observations,
        evaluations,
        drills,
        condition,
        reports,
        reportsProgress,
        tracking,
        episodes,
        markers,
        challenges,
        solutions,
        tasks,
        userTasks,
        scoutingLists,
        notifications,
        cycles,
        storageFiles,
        clubs,
        leagues,
        medicalRecords,
        medicalTests,
        injuries,
    }),
    ui: combineReducers({
        calendar,
        auth,
        reportPage,
        invitationPage,
        evaluationsPage,
        challengesPage,
        reportTagging,
    }),
});
export const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
/* eslint-disable no-underscore-dangle */
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;

export type RootState = ReturnType<typeof store.getState>;

// entities
sagaMiddleware.run(userSaga);
sagaMiddleware.run(accessSagas);
sagaMiddleware.run(playersSagas);
sagaMiddleware.run(eventsSaga);
sagaMiddleware.run(goalsSagas);
sagaMiddleware.run(methodologiesSaga);
sagaMiddleware.run(organizationsSagas);
sagaMiddleware.run(teamsSagas);
sagaMiddleware.run(agentsSagas);
sagaMiddleware.run(agenciesSagas);
sagaMiddleware.run(invitationsSagas);
sagaMiddleware.run(observationsSagas);
sagaMiddleware.run(evaluationsSagas);
sagaMiddleware.run(drillsSaga);
sagaMiddleware.run(conditionSagas);
sagaMiddleware.run(episodesSaga);
sagaMiddleware.run(trackingSaga);
sagaMiddleware.run(markersSaga);
sagaMiddleware.run(reportsSaga);
sagaMiddleware.run(reportsProgressSaga);
sagaMiddleware.run(challengesSaga);
sagaMiddleware.run(solutionsSaga);
sagaMiddleware.run(tasksSaga);
sagaMiddleware.run(userTasksSaga);
sagaMiddleware.run(emailsSagas);
sagaMiddleware.run(scoutingListsSagas);
sagaMiddleware.run(notificationsSagas);
sagaMiddleware.run(cyclesSagas);
sagaMiddleware.run(storageFilesSagas);
sagaMiddleware.run(clubsSagas);
sagaMiddleware.run(leaguesSagas);
sagaMiddleware.run(medicalRecordsSagas);
sagaMiddleware.run(medicalTestsSagas);
sagaMiddleware.run(fitnessDataSagas);
sagaMiddleware.run(injuriesSagas);
sagaMiddleware.run(clubLocationsSagas);
// ui
sagaMiddleware.run(calendarSaga);
sagaMiddleware.run(invitationSagas);
sagaMiddleware.run(authSagas);
sagaMiddleware.run(sessionSaga);
sagaMiddleware.run(playerSagas);
sagaMiddleware.run(evaluationPageSagas);
sagaMiddleware.run(reportSetupPageSagas);
sagaMiddleware.run(reportTaggingPageSaga);
sagaMiddleware.run(challengesPageSagas);
sagaMiddleware.run(reportPageSagas);
sagaMiddleware.run(scoutingSagas);
// common
sagaMiddleware.run(appConfigSagas);
