import TeamStats from 'entities/teams/TeamStats';
import { httpsCallable } from 'firebase/functions';
import Team, { GameTypesMetadata, TeamData } from 'entities/teams/Team';
import { PlayersLabelsMetadata } from 'entities/teams/TeamPlayersLabels';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db, functions } from '../common/firebase';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';
import { parseOgrTeamsListResponse, parseTeamStatsResponse } from './teamGateway.utils';

export const getOrgTeamsPath = (organizationId: string) => {
    return `organizations/${organizationId}/teams`;
};

export const getTeamDocPath = (organizationId: string, teamId: string) => {
    return `${getOrgTeamsPath(organizationId)}/${teamId}`;
};

export const getTeamStorageOtherFilesPath = (organizationId: string, teamId: string) => {
    return `${getTeamDocPath(organizationId, teamId)}/files`;
};

export const getPlayersLabelsMetadataDocPath = (organizationId: string, teamId: string) => {
    return `${getTeamDocPath(organizationId, teamId)}/metadata/playersLabels`;
};

export const getPlayersLabelsMetadataDocRef = (organizationId: string, teamId: string) => {
    return doc(db, getPlayersLabelsMetadataDocPath(organizationId, teamId));
};

export const getGameTypesMetadataDocPath = (organizationId: string, teamId: string) => {
    return `${getTeamDocPath(organizationId, teamId)}/metadata/gameTypes`;
};

export const getGameTypesMetadataDocRef = (organizationId: string, teamId: string) => {
    return doc(db, getGameTypesMetadataDocPath(organizationId, teamId));
};

export const getTeamUpdatesPath = (organizationId: string, teamId: string) => {
    return `${getTeamDocPath(organizationId, teamId)}/teamUpdates`;
};

const mapTeamData = (teamData: TeamData | null, organizationId: string) => {
    return teamData ? { ...teamData, organizationId } : null;
};

const createTeamFunction = httpsCallable(functions, 'createTeam');
export const createTeam = ({
    organizationId,
    teamName,
}: {
    organizationId: string;
    teamName: string;
}) => {
    return createTeamFunction({ organizationId, teamName });
};

const deleteTeamFunction = httpsCallable(functions, 'deleteTeam');
export const deleteTeam = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    return deleteTeamFunction({ organizationId, teamId });
};

export const fetchTeam = ({
    teamId,
    organizationId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const docRef = doc(db, getTeamDocPath(organizationId, teamId));
    return getDoc(docRef)
        .then(handleDocumentSnapshot)
        .then((team) => mapTeamData(team as Team, organizationId));
};

export const assignOrgId = (teamsList: Team[], organizationId: string): Team[] => {
    return teamsList
        .map((team: Team) => mapTeamData(team as Team, organizationId))
        .filter((team) => team !== null) as Team[];
};

export const fetchTeamsList = ({ organizationId }: { organizationId: string }) => {
    const q = query(
        collection(db, getOrgTeamsPath(organizationId)),
        where('status', 'in', ['active', 'archived']),
    );
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((teamsList) => assignOrgId(teamsList, organizationId));
};

export const updateTeamData = ({
    teamId,
    organizationId,
    teamData,
}: {
    organizationId: string;
    teamId: string;
    teamData: TeamData;
}) => {
    const teamRef = doc(db, getTeamDocPath(organizationId, teamId));
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(teamData, { lastUpdateAuthorUid });

    return updateDoc(teamRef, teamData);
};

const getTeamStatsFunction = httpsCallable(functions, 'getTeamStatsV2');
export const getTeamStats = ({
    teamId,
    organizationId,
    fromDate,
    toDate,
}: {
    teamId: string;
    organizationId: string;
    fromDate?: string;
    toDate?: string;
}) => {
    return getTeamStatsFunction({ teamId, organizationId, fromDate, toDate }).then((res) => {
        return parseTeamStatsResponse(res.data as TeamStats);
    });
};

/*
 * firestore rules allows fetching teams list for org admins only
 * This request allows this for team coaches and admins
 */
const getOrgTeamsListFunction = httpsCallable(functions, 'getOrgTeamsV2');
export const getOrgTeamsList = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    return getOrgTeamsListFunction({ organizationId, teamId }).then((res) => {
        return parseOgrTeamsListResponse(res.data as Team[]);
    });
};

const archiveTeamFunction = httpsCallable(functions, 'archiveTeam');
export const archiveTeam = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    return archiveTeamFunction({ organizationId, teamId });
};

const unarchiveTeamFunction = httpsCallable(functions, 'unarchiveTeam');
export const unarchiveTeam = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    return unarchiveTeamFunction({ organizationId, teamId });
};

export const fetchTeamUpdatesList = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => {
    const updatesRef = collection(db, getTeamUpdatesPath(organizationId, teamId));

    const q = query(updatesRef, where('teamId', '==', teamId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const updatePlayersLabelsMetadata = ({
    playersLabelsMetadata,
    organizationId,
    teamId,
}: {
    playersLabelsMetadata: PlayersLabelsMetadata;
    organizationId: string;
    teamId: string;
}) => {
    const playersLabelsMetadataRef = getPlayersLabelsMetadataDocRef(organizationId, teamId);

    return updateDoc(playersLabelsMetadataRef, playersLabelsMetadata);
};

export const fetchPlayersLabels = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const playersLabelsMetadataRef = getPlayersLabelsMetadataDocRef(organizationId, teamId);

    return getDoc(playersLabelsMetadataRef).then(handleDocumentSnapshot);
};

export const updateGameTypesMetadata = ({
    gameTypesMetadata,
    organizationId,
    teamId,
}: {
    gameTypesMetadata: GameTypesMetadata;
    organizationId: string;
    teamId: string;
}) => {
    const gameTypesMetadataRef = getGameTypesMetadataDocRef(organizationId, teamId);

    return updateDoc(gameTypesMetadataRef, gameTypesMetadata);
};

export const fetchGameTypesMetadata = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const gameTypesMetadataRef = getGameTypesMetadataDocRef(organizationId, teamId);

    return getDoc(gameTypesMetadataRef).then(handleDocumentSnapshot);
};
