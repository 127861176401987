import { db } from 'common/firebase';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import {
    ClubLocation,
    ClubLocationData,
    LocationTypesMetadata,
} from 'entities/club-locations/ClubLocations';
import { getOrgPath } from './organizationsGateway';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const getOrgLocationCollectionPath = (organizationId: string) => {
    return `${getOrgPath(organizationId)}/locations`;
};

export const getClubLocationStoragePath = ({
    organizationId,
    clubLocationId,
}: {
    organizationId: string;
    clubLocationId: string;
}) => {
    return `${getOrgPath(organizationId)}/locations/${clubLocationId}`;
};

export const getOrgLocationTypesPath = (organizationId: string) => {
    return `${getOrgPath(organizationId)}/metadata/locationTypes`;
};

export const getOrgLocationTypesDocRef = (organizationId: string) => {
    return doc(db, getOrgLocationTypesPath(organizationId));
};

export const createClubLocation = ({
    organizationId,
    clubLocationData,
}: {
    organizationId: string;
    clubLocationData: ClubLocationData;
}) => {
    const clubLocationsRef = collection(db, getOrgLocationCollectionPath(organizationId));

    return addDoc(clubLocationsRef, clubLocationData).then((doc) => doc.id);
};

export const updateClubLocation = ({
    organizationId,
    clubLocationId,
    clubLocation,
}: {
    organizationId: string;
    clubLocationId: string;
    clubLocation: ClubLocation;
}) => {
    const locationRef = doc(db, getOrgLocationCollectionPath(organizationId), clubLocationId);

    return updateDoc(locationRef, clubLocation);
};

export const fetchClubLocationsList = ({ organizationId }: { organizationId: string }) => {
    const clubLocationsRef = collection(db, getOrgLocationCollectionPath(organizationId));

    return getDocs(clubLocationsRef).then(handleCollectionSnapshot);
};

export const deleteClubLocation = ({
    organizationId,
    clubLocationId,
}: {
    organizationId: string;
    clubLocationId: string;
}) => {
    const locationRef = doc(db, getOrgLocationCollectionPath(organizationId), clubLocationId);

    return deleteDoc(locationRef);
};

export const updateLocationTypesMetadata = ({
    locationTypesMetadata,
    organizationId,
}: {
    locationTypesMetadata: LocationTypesMetadata;
    organizationId: string;
}) => {
    const locationTypesMetadataRef = getOrgLocationTypesDocRef(organizationId);

    return updateDoc(locationTypesMetadataRef, locationTypesMetadata);
};

export const fetchLocationTypesMetadata = ({ organizationId }: { organizationId: string }) => {
    const locationTypesMetadataRef = getOrgLocationTypesDocRef(organizationId);

    return getDoc(locationTypesMetadataRef).then(handleDocumentSnapshot);
};
