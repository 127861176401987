import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    createClubLocation,
    deleteClubLocation,
    fetchClubLocationsList,
    fetchLocationTypesMetadata,
    updateClubLocation,
    updateLocationTypesMetadata,
} from 'gateways/clubLocationsGateway';
import actionTypes from './clubLocations.actionTypes';
import { ClubLocation, LocationTypesMetadata } from './ClubLocations';
import {
    clubLocationDeleteRequest,
    clubLocationsListReceived,
    clubLocationsListRequest,
    createClubLocationRequest,
    locationTypesDataReceived,
    locationTypesDataRequest,
    locationTypesDataUpdate,
    removeClubLocation,
    updateClubLocationRequest,
} from './clubLocations.actions';

export function* createClubLocationSaga({
    clubLocationData,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof createClubLocationRequest>) {
    try {
        const locationId: string = yield call(createClubLocation, {
            organizationId,
            clubLocationData,
        });
        yield put(clubLocationsListRequest({ organizationId, onFail }));
        onSuccess(locationId);
    } catch (e: any) {
        yield call(onFail);
    }
}

export function* updateClubLocationSaga({
    organizationId,
    clubLocationId,
    clubLocation,
    onFail,
}: ReturnType<typeof updateClubLocationRequest>) {
    try {
        yield call(updateClubLocation, {
            organizationId,
            clubLocationId,
            clubLocation,
        });
        yield put(clubLocationsListRequest({ organizationId, onFail }));
    } catch (e) {
        console.error(`Failed to update location ${clubLocationId}`, e);
        yield call(onFail);
    }
}

export function* fetchClubLocationsListSaga({
    organizationId,
    onFail,
}: ReturnType<typeof clubLocationsListRequest>) {
    try {
        const clubLocationsList: ClubLocation[] = yield call(fetchClubLocationsList, {
            organizationId,
        });
        yield put(clubLocationsListReceived({ clubLocationsList }));
    } catch (e: any) {
        console.error(e);
        if (e.code === 'permission-denied') {
            yield call(onFail);
        }
    }
}

export function* deleteClubLocationSaga({
    clubLocationId,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof clubLocationDeleteRequest>) {
    try {
        yield call(deleteClubLocation, { clubLocationId, organizationId });
        yield put(removeClubLocation({ clubLocationId }));
        yield call(onSuccess);
    } catch (e) {
        yield call(onFail);
    }
}

export function* updateLocationTypesMetadataSaga({
    organizationId,
    locationTypesMetadata,
}: ReturnType<typeof locationTypesDataUpdate>) {
    try {
        yield call(updateLocationTypesMetadata, {
            organizationId,
            locationTypesMetadata,
        });
        yield put(locationTypesDataRequest({ organizationId }));
    } catch (e: any) {
        console.error('Location types update error', e);
        throw new Error(e);
    }
}

export function* fetchClubLocationsTypesMetadataSaga({
    organizationId,
}: ReturnType<typeof locationTypesDataRequest>) {
    try {
        const locationTypesMetadata: LocationTypesMetadata = yield call(
            fetchLocationTypesMetadata,
            { organizationId },
        );

        if (locationTypesMetadata) {
            yield put(
                locationTypesDataReceived({
                    locationTypesMetadata,
                    organizationId,
                }),
            );
        }
    } catch (e: any) {
        console.error('Locations types fetch error', e);
        throw new Error(e);
    }
}

export default function* clubLocationsSagas() {
    yield all([
        takeLatest(actionTypes.CREATE_CLUB_LOCATION_REQUEST, createClubLocationSaga),
        takeLatest(actionTypes.UPDATE_CLUB_LOCATION_REQUEST, updateClubLocationSaga),
        takeLatest(actionTypes.CLUB_LOCATIONS_LIST_REQUEST, fetchClubLocationsListSaga),
        takeLatest(actionTypes.CLUB_LOCATION_DELETE_REQUEST, deleteClubLocationSaga),
        takeLatest(actionTypes.CLUB_LOCATION_TYPES_UPDATE, updateLocationTypesMetadataSaga),
        takeLatest(actionTypes.CLUB_LOCATION_TYPES_REQUEST, fetchClubLocationsTypesMetadataSaga),
    ]);
}
