import { httpsCallable } from 'firebase/functions';
import { auth, db, functions } from 'common/firebase';
import { PlayerUpdateType } from 'entities/players/PlayerUpdates';
import Player, { PlayerData, PlayerStatus } from 'entities/players/Player';
import { mapPlayerFromDatesStrings } from 'entities/players/players.utils';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const getPlayerDocPath = (playerId: string) => {
    return `players/${playerId}`;
};

export const getPlayerStorageFilesPath = (playerId: string) => {
    return `players/${playerId}`;
}

export const getPlayerUpdatesCollectionPath = (playerId: string) => {
    return `players/${playerId}/playerUpdates`;
};

export const getPlayerUpdatesCollectionRef = (playerId: string) => {
    return collection(db, getPlayerUpdatesCollectionPath(playerId));
};

export const getPlayerDocRef = (playerId: string) => {
    return doc(db, `players/${playerId}`);
};

export const getPlayerSkillsDocPath = (playerId: string) => {
    return `players/${playerId}/metadata/skills`;
};

export const getPlayerSkillsDocRef = (playerId: string) => {
    const docPath = getPlayerSkillsDocPath(playerId);

    return doc(db, docPath);
};

export const fetchPlayerById = ({ playerId }: { playerId: string }) => {
    const playerRef = getPlayerDocRef(playerId);

    return getDoc(playerRef).then(handleDocumentSnapshot);
};

export const fetchPlayerSkills = (playerId: string) => {
    const playerSkillsRef = getPlayerSkillsDocRef(playerId);

    return getDoc(playerSkillsRef).then(handleDocumentSnapshot);
};

export const fetchOrganizationPlayersList = ({ organizationId }: { organizationId: string }) => {
    const playersRef = collection(db, 'players');
    const q = query(
        playersRef,
        where(`organizations.${organizationId}.active`, '==', true),
        where('status', '==', PlayerStatus.active),
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchScoutingNoCountryPlayersList = ({
    organizationId,
}: {
    organizationId: string;
}) => {
    const playersRef = collection(db, 'players');
    const conditions = [
        where('teams.scouting.organizationId', '==', organizationId),
        where('status', '==', PlayerStatus.active),
        where('countries', '==', []),
    ];

    const q = query(playersRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchScoutingRecentPlayersList = ({ organizationId }: { organizationId: string }) => {
    const playersRef = collection(db, 'players');
    const conditions = [
        where('teams.scouting.organizationId', '==', organizationId),
        where('status', '==', PlayerStatus.active),
    ];

    const q = query(playersRef, ...conditions, orderBy('createDate', 'desc'), limit(50));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchScoutingNoRefPlayersList = ({ organizationId }: { organizationId: string }) => {
    const playersRef = collection(db, 'players');
    const conditions = [
        where('teams.scouting.organizationId', '==', organizationId),
        where('status', '==', PlayerStatus.active),
        where('references', '==', {}),
    ];

    const q = query(playersRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchScoutingClubPlayersList = ({ clubId }: { clubId: string }) => {
    const playersRef = collection(db, 'players');
    const conditions = [
        where('club.clubId', '==', clubId),
        where('status', '==', PlayerStatus.active),
    ];

    const q = query(playersRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchTeamPlayersList = ({ teamId }: { teamId: string }) => {
    const playersRef = collection(db, 'players');

    const q = query(
        playersRef,
        where(`teams.${teamId}.active`, '==', true),
        where('status', '==', PlayerStatus.active),
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchAgentPlayersList = ({ agentId }: { agentId: string }) => {
    const playersRef = collection(db, 'players');

    const q = query(playersRef, where('agent.id', '==', agentId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchPlayerUpdatesList = ({
    playerId,
    organizationId,
    updatesType,
}: {
    playerId: string;
    organizationId: string;
    updatesType?: PlayerUpdateType;
}) => {
    const playerUpdatesRef = getPlayerUpdatesCollectionRef(playerId);
    const restrictions = [where('organizationId', '==', organizationId)];

    if (updatesType) {
        restrictions.push(where('details.type', '==', updatesType));
    }

    const q = query(playerUpdatesRef, ...restrictions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const createPlayer = ({ playerData }: { playerData: PlayerData }) => {
    const playersRef = collection(db, 'players');
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(playerData, { lastUpdateAuthorUid });

    return addDoc(playersRef, playerData).then((doc) => doc.id);
};

export const editPlayer = ({
    playerId,
    playerData,
}: {
    playerId: string;
    playerData: PlayerData;
}) => {
    const playerRef = doc(db, `players/${playerId}`);

    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    Object.assign(playerData, { lastUpdateAuthorUid });

    return updateDoc(playerRef, playerData);
};

export const removePlayer = ({ playerId }: { playerId: string }) => {
    const playerRef = doc(db, `players/${playerId}`);

    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    return updateDoc(playerRef, {
        status: PlayerStatus.deleted,
        lastUpdateAuthorUid,
    });
};

export type PlayerSearchResult =
    | {
          status: 'error';
      }
    | {
          status: 'success';
          playerId: 'new';
          playerData: Player;
          avatarStr: null | string;
      };

const getProfileDataFunction = httpsCallable(functions, 'getProfileDataV2');
export const getProfileData = ({
    pageUrl,
    organizationId,
}: {
    pageUrl: string;
    organizationId: string;
}) => {
    return getProfileDataFunction({ pageUrl, organizationId }).then((res) => {
        const result = res.data as PlayerSearchResult;

        if (result.status === 'error') {
            throw new Error();
        }

        return {
            playerId: result.playerId,
            playerData: mapPlayerFromDatesStrings(result.playerData),
            avatarStr: result.avatarStr,
        };
    });
};

export const scrapTeamByExternalLink = httpsCallable(functions, 'scrapTeamByExternalLink');

export const updatePlayerSkills = httpsCallable(functions, 'updatePlayerSkillsV2');
