import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'ToggleButtons';

const classes = {
    root: `${PREFIX}-root`,
    grouped: `${PREFIX}-grouped`,
    label: `${PREFIX}-label`,
    selected: `${PREFIX}-selected`,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    padding: 2,
    border: `1px solid #e7e4ea`,
    backgroundColor: theme.custom.background.light,
    height: 32,
    flexShrink: 0,
    borderRadius: 4,

    [`& .${classes.grouped}`]: {
        border: 'none',
        '&:not(:first-of-type)': {
            borderRadius: 3,
            marginLeft: 2,
        },
        '&:first-of-type': {
            borderRadius: 3,
        },
    },

    [`& .${classes.label}`]: {
        textTransform: 'capitalize',
    },

    [`& .${classes.selected}`]: {
        backgroundColor: 'rgba(33,150,243, 0.12) !important',
        color: '#2196F3 !important',
    },
}));

export type Option = {
    value: string | number;
    label: string;
    disabled?: boolean;
};

type ToggleButtonsProps = {
    value: string | number | Enumerator;
    options: Option[];
    className?: string;
    toggleButtonClasses?: string;
    sx?: any;
    disabled?: boolean;
    onChange: (value: any) => void;
};

function getOptionWidth(options: Option[]): number {
    const charLengthInPx = 9;
    const padding = 8;
    const maxLabelLenght = Math.max(...options.map((option) => option.label.length));
    return Math.min(maxLabelLenght * charLengthInPx + padding * 2, 100);
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
    options,
    toggleButtonClasses,
    onChange,
    ...rest
}) => {
    const optionWidth = getOptionWidth(options);

    const handleChange = (_: any, val: any) => {
        if (val !== null) {
            onChange(val);
        }
    };

    return (
        <StyledToggleButtonGroup
            size="small"
            onChange={handleChange}
            exclusive
            classes={{
                root: classes.root,
                grouped: classes.grouped,
            }}
            {...rest}
        >
            {options.map((option) => (
                <ToggleButton
                    key={option.value}
                    value={option.value}
                    classes={{
                        root: classes.label,
                        selected: toggleButtonClasses || classes.selected,
                    }}
                    style={{ minWidth: optionWidth, flexGrow: 1 }}
                    disabled={option.disabled}
                >
                    {option.label}
                </ToggleButton>
            ))}
        </StyledToggleButtonGroup>
    );
};

export default ToggleButtons;
