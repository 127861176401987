import PlayerType, { PlayerData, PlayerSkills } from './Player';
import { PlayerUpdate, PlayerUpdateType } from './PlayerUpdates';
import actionTypes from './players.actionTypes';

export const playerDataRequest = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.PLAYER_DATA_REQUEST,
    playerId,
});

export const playerDataReceived = (playerData: PlayerType) => ({
    type: actionTypes.PLAYER_DATA_RECEIVED,
    playerData,
});

export const requestTeamPlayersList = ({ teamId }: { teamId: string }) => ({
    type: actionTypes.TEAM_PLAYERS_LIST_REQUEST,
    teamId,
});

export const requestAgentPlayersList = ({ agentId }: { agentId: string }) => ({
    type: actionTypes.AGENT_PLAYERS_LIST_REQUEST,
    agentId,
});

export const requestPlayerUpdatesList = ({
    organizationId,
    playerId,
    updatesType,
}: {
    organizationId: string;
    playerId: string;
    updatesType?: PlayerUpdateType;
}) => ({
    type: actionTypes.PLAYERS_UPDATES_LIST_REQUEST,
    organizationId,
    playerId,
    updatesType,
});

export const playerUpdatesListReceived = ({
    playerUpdatesList,
}: {
    playerUpdatesList: PlayerUpdate[];
}) => ({
    type: actionTypes.PLAYERS_UPDATES_LIST_RECEIVED,
    playerUpdatesList,
});

export const playersListReceived = (playersList: PlayerType[]) => ({
    type: actionTypes.PLAYERS_LIST_RECEIVED,
    playersList,
});

export const teamPlayersRequested = (teamId: string) => ({
    type: actionTypes.TEAM_PLAYERS_REQUESTED,
    teamId,
});

// export const orgPlayersRequested = (organizationId: string) => ({
//     type: actionTypes.ORG_PLAYERS_REQUESTED,
//     organizationId,
// });

export const scoutingClubPlayersRequest = ({ clubId }: { clubId: string }) => ({
    type: actionTypes.SCOUTING_CLUB_PLAYERS_REQUESTED,
    clubId,
});

export const scoutingNoCountryPlayersRequest = (organizationId: string) => ({
    type: actionTypes.SCOUTING_NO_COUNTRY_PLAYERS_REQUEST,
    organizationId,
});

export const scoutingRecentPlayersRequest = (organizationId: string) => ({
    type: actionTypes.SCOUTING_RECENT_PLAYERS_REQUEST,
    organizationId,
});

export const scoutingNoRefPlayersRequest = (organizationId: string) => ({
    type: actionTypes.SCOUTING_NO_REF_PLAYERS_REQUEST,
    organizationId,
});

export const playersListLoadError = () => ({
    type: actionTypes.PLAYERS_LIST_LOAD_ERROR,
});

export const removePlayerFromLocalState = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.REMOVE_PLAYER_DATA,
    playerId,
});

export const updatePlayer = ({
    playerId,
    playerData,
    onSuccess,
}: {
    playerId: string;
    playerData: PlayerData;
    onSuccess?: () => void;
}) => ({
    type: actionTypes.UPDATE_PLAYER_DATA,
    playerId,
    playerData,
    onSuccess,
});

export const removePlayer = ({
    playerId,
    onSuccess,
}: {
    playerId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.REMOVE_PLAYER,
    playerId,
    onSuccess,
});

export const setRemovedPlayer = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.SET_REMOVED_PLAYER,
    playerId,
});

export const playerSkillsRequest = (playerId: string) => ({
    type: actionTypes.PLAYERS_SKILLS_REQUEST,
    playerId,
});

export const playerSkillsReceived = ({
    playerId,
    skills,
}: {
    playerId: string;
    skills: PlayerSkills;
}) => ({
    type: actionTypes.PLAYERS_SKILLS_RECEIVED,
    playerId,
    skills,
});

export const updatePlayerSkills = ({
    playerId,
    skills,
}: {
    playerId: string;
    skills: PlayerSkills;
}) => ({
    type: actionTypes.UPDATE_PLAYERS_SKILLS,
    playerId,
    skills,
});
