import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import { StorageInfo } from 'entities/storage-files/StorageFile';
import actionTypes from './organizations.actionTypes';
import Organization from './Organization';

type OrganisationsState = {
    organizationsHash: Record<string, Organization>;
    organizationsStorageInfoHash: Record<string, StorageInfo>;
    organizationsPlayersInfoHash: Record<string, Record<string, string>>;
    organizationsClubsInfoHash: Record<string, Record<string, string>>;
};

const initialState = {
    organizationsHash: {},
    organizationsStorageInfoHash: {},
    organizationsPlayersInfoHash: {},
    organizationsClubsInfoHash: {},
};

export default (state: OrganisationsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_DATA_RECIEVED:
            return {
                ...state,
                organizationsHash: {
                    ...state.organizationsHash,
                    [action.organizationId]: {
                        ..._get(state, `organizationsHash.${action.organizationId}`, {}),
                        ...action.organizationData,
                    },
                },
            };
        case actionTypes.ORGANIZATIONS_LIST_RECIEVED:
            return {
                ...state,
                organizationsHash: {
                    ...state.organizationsHash,
                    ..._keyBy(action.organizationsList, 'id'),
                },
            };
        case actionTypes.ORGANIZATION_STORAGE_INFO_RECIEVED:
            return {
                ...state,
                organizationsStorageInfoHash: {
                    ...state.organizationsStorageInfoHash,
                    [action.organizationId]: action.orgStorageInfo,
                },
            };
        case actionTypes.ORGANIZATION_PLAYERS_INFO_RECEIVED:
            return {
                ...state,
                organizationsPlayersInfoHash: {
                    ...state.organizationsPlayersInfoHash,
                    [action.organizationId]: action.organizationPlayersInfo,
                },
            };
        case actionTypes.ORGANIZATION_CLUBS_INFO_RECEIVED:
            return {
                ...state,
                organizationsClubsInfoHash: {
                    ...state.organizationsClubsInfoHash,
                    [action.organizationId]: action.organizationClubsInfo,
                },
            };
        default:
            return state;
    }
};
