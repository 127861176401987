import { t } from 'i18next';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';

export const togglePlayerLabel = (
    playersLabelsMetadata: PlayersLabelsMetadata,
    playerId: string,
    labelId: string,
) => {
    const currentLabelsIds = playersLabelsMetadata.playersMapping[playerId]?.labels || [];

    const updatedLabelsIds = currentLabelsIds.includes(labelId)
        ? currentLabelsIds.filter((id) => id !== labelId)
        : currentLabelsIds.concat(labelId);

    return updatedLabelsIds;
};

export const getPlayerLabelsNameTranslations = (shortName: string) => {
    return t(`playersLabels.standardLabels.${shortName}`);
};

export const getGameTypesTranslations = (shortName: string) => {
    return t(`gameTypes.standardGames.${shortName}`);
};

export const getDrillTypesNameTranslations = (shortName: string) => {
    return t(`drills.drillTypes.${shortName}`);
};