import React from 'react';
import { SxProps, styled } from '@mui/material/styles';
import { Box, Typography } from 'common/components';
import { InfoIcon } from 'common/icons';

const StyledDescriptionText = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    background: '#ffa50036',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
}));

type CalloutProps = {
    text: string;
    sx?: SxProps;
};

export const Callout: React.FC<CalloutProps> = ({ text, ...rest }) => {
    return (
        <StyledDescriptionText {...rest}>
            <InfoIcon sx={{ mr: 1, width: 16, height: 16 }} />
            <Typography variant="body2">{text}</Typography>
        </StyledDescriptionText>
    );
};
