import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref } from 'firebase/storage';
import { StorageInfo } from 'entities/storage-files/StorageFile';
import { ListsFolder } from 'entities/scouting-lists/ScoutingList';
import { OrganizationData, OrganizationParams } from 'entities/organizations/Organization';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { db, functions, storage } from '../common/firebase';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const getOrgStorageOtherFilesPath = (organizationId: string) => {
    return `organizations/${organizationId}/files`;
};

export const getOrgPath = (organizationId: string) => {
    return `organizations/${organizationId}`;
};

export const getOrgMetadataPath = (organizationId: string) => {
    return `${getOrgPath(organizationId)}/metadata`;
};

export const getOrgMetadataStoragePath = (organizationId: string) => {
    return `${getOrgPath(organizationId)}/metadata`;
};

export const fetchOrganizationById = ({ organizationId }: OrganizationParams) => {
    const organizationRef = doc(db, `organizations/${organizationId}`);

    return getDoc(organizationRef).then(handleDocumentSnapshot);
};

export const fetchOrganizationsList = ({ count }: { count?: number }) => {
    const organizationsRef = collection(db, 'organizations');
    const queryEntries = [];

    if (count) {
        queryEntries.push(orderBy('lastActivity', 'desc'));
        queryEntries.push(limit(count));
    }

    const q = query(organizationsRef, ...queryEntries);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchOrganizationsStorageInfo = ({ organizationId }: { organizationId: string }) => {
    const organizationStorageInfoRef = doc(db, `${getOrgMetadataPath(organizationId)}/storage`);

    return getDoc(organizationStorageInfoRef).then(handleDocumentSnapshot);
};

export const updateOrganizationsStorageInfo = ({
    orgStorageInfo,
    organizationId,
}: {
    orgStorageInfo: StorageInfo;
    organizationId: string;
}) => {
    const organizationStorageInfoRef = doc(db, `${getOrgMetadataPath(organizationId)}/storage`);

    return updateDoc(organizationStorageInfoRef, orgStorageInfo);
};

export const fetchOrganizationsPlayersInfo = async ({
    organizationId,
}: {
    organizationId: string;
}) => {
    const organizationPlayersInfoStorageRef = ref(
        storage,
        `${getOrgMetadataStoragePath(organizationId)}/players.json`,
    );
    const fileUrl: string = await getDownloadURL(organizationPlayersInfoStorageRef);
    const response: Response = await fetch(fileUrl);

    if (!response.ok) {
        throw new Error('Failed to load markers csv');
    }

    const orgPlayersJson: string = await response.text();

    return JSON.parse(orgPlayersJson);
};

export const fetchOrganizationsClubsInfo = async ({
    organizationId,
}: {
    organizationId: string;
}) => {
    const organizationClubsInfoStorageRef = ref(
        storage,
        `${getOrgMetadataStoragePath(organizationId)}/clubs.json`,
    );
    const fileUrl: string = await getDownloadURL(organizationClubsInfoStorageRef);
    const response: Response = await fetch(fileUrl);

    if (!response.ok) {
        throw new Error('Failed to load org clubs json file');
    }

    const orgClubsJson: string = await response.text();

    return JSON.parse(orgClubsJson);
};

export const updateOrganizationData = ({
    organizationData,
    organizationId,
}: {
    organizationId: string;
    organizationData: OrganizationData;
}) => {
    const organizationRef = doc(db, `organizations/${organizationId}`);

    return updateDoc(organizationRef, organizationData);
};

const createOrganizationFunction = httpsCallable(functions, 'createOrganization');
export const createOrganization = ({ organizationName }: { organizationName: string }) => {
    return createOrganizationFunction({ organizationName });
};

export const fetchScoutingListFolders = ({ organizationId }: { organizationId: string }) => {
    const scoutingListFolders = doc(db, `${getOrgMetadataPath(organizationId)}/longlistFolders`);

    return getDoc(scoutingListFolders).then(handleDocumentSnapshot);
};

export const updateScoutingListFolders = async ({
    scoutingListFolders,
    organizationId,
}: {
    scoutingListFolders: ListsFolder[];
    organizationId: string;
}) => {
    const scoutingFoldersRef = doc(db, `${getOrgMetadataPath(organizationId)}/longlistFolders`);

    const docSnapshot = await getDoc(scoutingFoldersRef);

    if (docSnapshot.exists()) {
        const currentData = docSnapshot.data();
        const newData = { ...currentData, structure: scoutingListFolders };

        await setDoc(scoutingFoldersRef, newData);
    } else {
        await setDoc(scoutingFoldersRef, { structure: scoutingListFolders });
    }
};
