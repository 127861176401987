import { db, functions } from 'common/firebase';
import { formatISO, parseISO, setHours } from 'date-fns';
import { Condition, ConditionData } from 'entities/conditions/Condition';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

const getConditionsCollectionPath = (organizationId: string, teamId: string) => {
    return `organizations/${organizationId}/teams/${teamId}/conditions`;
};

const getConditionsCollectionRef = (organizationId: string, teamId: string) => {
    const conditionsCollectionPath = getConditionsCollectionPath(organizationId, teamId);
    return collection(db, conditionsCollectionPath);
};

const getConditionDocPath = (organizationId: string, teamId: string, conditionId: string) => {
    const conditionsCollectionPath = getConditionsCollectionPath(organizationId, teamId);
    return `${conditionsCollectionPath}/${conditionId}`;
};

const getConditionDocRef = (organizationId: string, teamId: string, conditionId: string) => {
    const conditionDocPath = getConditionDocPath(organizationId, teamId, conditionId);
    return doc(db, conditionDocPath);
};

const getConditionsDayReportDocPath = (
    organizationId: string,
    teamId: string,
    conditionsDayReportId: string,
) => {
    return `organizations/${organizationId}/teams/${teamId}/conditionsReports/${conditionsDayReportId}`;
};

export const getConditionsDayReportDocRef = (
    organizationId: string,
    teamId: string,
    conditionsDayReportId: string,
) => {
    const conditionsDayReportDocPath = getConditionsDayReportDocPath(
        organizationId,
        teamId,
        conditionsDayReportId,
    );
    return doc(db, conditionsDayReportDocPath);
};

export const fetchConditionListByRange = ({
    organizationId,
    teamId,
    fromDate,
    toDate,
}: {
    organizationId: string;
    teamId: string;
    fromDate: Date;
    toDate: Date;
}) => {
    const conditionsRef = getConditionsCollectionRef(organizationId, teamId);
    const q = query(conditionsRef, where('date', '>=', fromDate), where('date', '<', toDate));
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((conditions) => conditions.map((condition: Condition) => ({ ...condition, teamId })));
};

export const fetchConditionsForEvent = ({
    organizationId,
    teamId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    eventId: string;
}) => {
    const conditionsRef = getConditionsCollectionRef(organizationId, teamId);
    const q = query(conditionsRef, where('details.eventId', '==', eventId));
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((conditions) => conditions.map((condition: Condition) => ({ ...condition, teamId })));
};

export const fetchConditionData = ({
    organizationId,
    teamId,
    conditionId,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
}) => {
    const conditionRef = getConditionDocRef(organizationId, teamId, conditionId);

    return getDoc(conditionRef)
        .then(handleDocumentSnapshot)
        .then((condition) => ({ ...condition, teamId }));
};

export const fetchPlayerEventCondition = async ({
    organizationId,
    teamId,
    playerId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    playerId: string;
    eventId: string;
}) => {
    const conditionsRef = getConditionsCollectionRef(organizationId, teamId);
    const q = query(
        conditionsRef,
        where('playerId', '==', playerId),
        where('details.eventId', '==', eventId),
    );
    const conditions = await getDocs(q)
        .then(handleCollectionSnapshot)
        .then((conditions) => conditions.map((condition: Condition) => ({ ...condition, teamId })));

    return (conditions[0] as Condition) || null;
};

export const fetchConditionsDayReport = ({
    organizationId,
    teamId,
    conditionsDayReportId,
}: {
    organizationId: string;
    teamId: string;
    conditionsDayReportId: string;
}) => {
    const dayConditionsDocRef = getConditionsDayReportDocRef(
        organizationId,
        teamId,
        conditionsDayReportId,
    );

    return getDoc(dayConditionsDocRef).then(handleDocumentSnapshot);
};

export const addConditionData = ({
    organizationId,
    teamId,
    conditionData,
}: {
    organizationId: string;
    teamId: string;
    conditionData: ConditionData;
}) => {
    const conditionsRef = getConditionsCollectionRef(organizationId, teamId);

    return addDoc(conditionsRef, conditionData)
        .then((conditionRef) => getDoc(conditionRef))
        .then(handleDocumentSnapshot)
        .then((condition) => ({ ...condition, teamId }));
};

export const editConditionData = ({
    organizationId,
    teamId,
    conditionId,
    conditionData,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
    conditionData: ConditionData;
}) => {
    const conditionRef = getConditionDocRef(organizationId, teamId, conditionId);

    return updateDoc(conditionRef, conditionData);
};

export const deleteCondition = ({
    organizationId,
    teamId,
    conditionId,
}: {
    organizationId: string;
    teamId: string;
    conditionId: string;
}) => {
    const conditionRef = getConditionDocRef(organizationId, teamId, conditionId);

    return deleteDoc(conditionRef);
};

export const getConditionsDayTable = ({
    date,
    organizationId,
    teamId,
}: {
    date: Date;
    teamId: string;
    organizationId: string;
}) => {
    return httpsCallable(
        functions,
        'getConditionsDayTable',
    )({
        // to tackle different timezones of the server and the client
        date: formatISO(setHours(date, 12)),
        organizationId,
        teamId,
    }).then((res) => {
        const { events, conditions, players } = res.data as any;
        const parsedEvents = (events as any[]).map(({ start, end, ...rest }) => ({
            start: parseISO(start),
            end: parseISO(end),
            ...rest,
        }));
        const parsedConditions = (conditions as any[]).map((condition) => ({
            ...condition,
            date: parseISO(condition.date),
        }));

        return {
            players,
            conditions: parsedConditions,
            events: parsedEvents,
        };
    });
};

export const generateConditionsDayReport = ({
    date,
    organizationId,
    teamId,
}: {
    date: Date;
    teamId: string;
    organizationId: string;
}) => {
    return httpsCallable(
        functions,
        'generateDayConditionsReport',
    )({
        // to tackle different timezones of the server and the client
        date: formatISO(setHours(date, 12)),
        organizationId,
        teamId,
    });
};
