import { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import { Permissions } from 'entities/access/Permissions';
import { TeamPageParams } from 'features/team/TeamPageTypes';
import { useTeamPermissions } from 'entities/access/access.utils';
import { useScoutingAccess } from 'features/scouting/scouting.utils';
import Player, { PlayerSkills } from './Player';
import { PlayerUpdateType } from './PlayerUpdates';
import {
    playerDataRequest,
    playerSkillsRequest,
    requestPlayerUpdatesList,
    requestTeamPlayersList,
    updatePlayerSkills,
} from './players.actions';
import {
    playerDataSelector,
    playersByIdsSelector,
    playerSkillsSelector,
    playerUpdatesListSelector,
    teamPlayersListSelector,
} from './players.selectors';

export const usePlayer = (playerId?: string | null) => {
    const dispatch = useDispatch();
    const player = useSelector((state: RootState) =>
        playerId ? playerDataSelector(state, { playerId }) : undefined,
    ) as Player | undefined;

    useEffect(() => {
        if (!player && playerId && playerId !== 'new') {
            dispatch(playerDataRequest({ playerId }));
        }
    }, [player]);

    return player;
};

export const usePlayerWithAccess = (playerId: string) => {
    const { teamId, organizationId } = useParams() as TeamPageParams;
    const hasPlayerPermissions = useTeamPermissions({ organizationId, teamId });
    const player = usePlayer(playerId);

    const isTeamPlayer =
        player?.organizations?.[organizationId]?.active && player?.teams?.[teamId]?.active;

    if (!isTeamPlayer || !hasPlayerPermissions(Permissions.canReadPlayerProfile)) return undefined;

    return player;
};

export const usePlayerWithScoutingAccess = ({
    playerId,
    organizationId,
}: {
    playerId: string;
    organizationId: string;
}) => {
    const { canReadPlayerGeneralInfo } = useScoutingAccess({
        organizationId,
    });
    const player = usePlayer(playerId);

    if (!canReadPlayerGeneralInfo) return undefined;

    return player;
};

export const useTeamPlayers = ({
    organizationId,
    teamId,
}: {
    organizationId?: string;
    teamId: string;
}) => {
    const dispatch = useDispatch();

    const playersList = useSelector((state: RootState) =>
        teamPlayersListSelector(state, {
            teamId,
            organizationId,
        }),
    );

    useEffect(() => {
        dispatch(requestTeamPlayersList({ teamId }));
    }, [teamId, dispatch]);

    return playersList;
};

export const usePlayerSkills = (playerId: string) => {
    const dispatch = useDispatch();

    const skills = useSelector((state: RootState) => playerSkillsSelector(state, { playerId }));

    useEffect(() => {
        if (!skills) {
            dispatch(playerSkillsRequest(playerId));
        }
    }, [dispatch]);

    const onUpdate = useCallback(
        (newSkills: PlayerSkills) => {
            dispatch(updatePlayerSkills({ playerId, skills: newSkills }));
        },
        [playerId],
    );

    return { skills, onUpdate };
};

export const usePlayerUpdates = ({
    organizationId,
    playerId,
    updatesType,
    skipRequest,
}: {
    organizationId: string;
    playerId: string;
    updatesType?: PlayerUpdateType;
    skipRequest?: boolean;
}) => {
    const dispatch = useDispatch();

    const playerUpdatesList = useSelector((state: RootState) =>
        playerUpdatesListSelector(state, { playerId, organizationId, updatesType }),
    );

    useEffect(() => {
        if (!skipRequest) {
            dispatch(requestPlayerUpdatesList({ playerId, organizationId, updatesType }));
        }
    }, [playerId, organizationId, skipRequest, dispatch]);

    return playerUpdatesList;
};

/* TODO: remove same method from scouting.utils.ts or from here */
export const usePlayersByIds = ({
    playersIds,
    refreshPredicate,
}: {
    playersIds: string[];
    refreshPredicate?: any;
}) => {
    const dispatch = useDispatch();
    const playersList = useSelector((state: RootState) =>
        playersByIdsSelector(state, { playersIds }),
    );

    useEffect(() => {
        const playersIdsWithData = playersList.map((player) => player.id);
        playersIds.forEach((playerId) => {
            if (!playersIdsWithData.includes(playerId)) {
                dispatch(playerDataRequest({ playerId }));
            }
        });
    }, [refreshPredicate]);

    return playersList;
};

export const useFilteredPlayers = ({ playersList }: { playersList: Player[] }) => {
    const [searchText, setSearchText] = useState<string>('');
    const [debouncedText, setDebouncedText] = useState<string>('');

    const handleFilterText = useMemo(() => {
        return _debounce(setDebouncedText, 500);
    }, []);

    const handleChange = useCallback(
        (text: string) => {
            setSearchText(text);
            handleFilterText(text);
        },
        [setSearchText, handleFilterText],
    );

    const filteredPlayers = useMemo(
        () =>
            playersList.filter((player) => {
                const { firstName, lastName, enLastName, enFirstName } = player;
                return [firstName, lastName, enLastName, enFirstName]
                    .join('')
                    .toLowerCase()
                    .includes(debouncedText);
            }),
        [debouncedText, playersList],
    );

    return { filteredPlayers, onTextChange: handleChange, searchText };
};
