import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import { Condition, ConditionsDayReport } from './Condition';
import actionTypes from './conditions.actionTypes';

type ConditionsState = {
    conditionsHash: Record<string, Condition>;
    dayConditionsReportsHash: Record<string, ConditionsDayReport>;
};

const initialState = {
    conditionsHash: {},
    dayConditionsReportsHash: {},
};

const condition = (state: ConditionsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CONDITIONS_LIST_RECEIVED:
            return {
                ...state,
                conditionsHash: {
                    ...state.conditionsHash,
                    ..._keyBy(action.conditionsList, 'id'),
                },
            };
        case actionTypes.CONDITION_DATA_RECEIVED:
            return {
                ...state,
                conditionsHash: {
                    ...state.conditionsHash,
                    [action.condition.id]: { ...action.condition },
                },
            };
        case actionTypes.CONDITIONS_DAY_REPORT_RECEIVED:
            return {
                ...state,
                dayConditionsReportsHash: {
                    ...state.dayConditionsReportsHash,
                    [action.conditionsDayReport.id]: { ...action.conditionsDayReport },
                },
            };
        case actionTypes.REMOVE_CONDITION:
            return {
                ...state,
                conditionsHash: { ..._omit(state.conditionsHash, action.conditionId) },
            };
        default:
            return state;
    }
};

export default condition;
