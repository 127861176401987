import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import actionTypes from './clubLocations.actionTypes';
import { ClubLocation, LocationTypesMetadata } from './ClubLocations';

type ClubLocationState = {
    clubLocationsHash: Record<string, ClubLocation>;
    clubLocationTypesHash: Record<string, LocationTypesMetadata>;
};

const initialState = {
    clubLocationsHash: {},
    clubLocationTypesHash: {},
};

export default (state: ClubLocationState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CLUB_LOCATION_RECEIVED:
            return {
                ...state,
                organizationClubLocationsHash: {
                    ...state.clubLocationsHash,
                    [action.clubLocation.id]: action.clubLocation,
                },
            };
        case actionTypes.CLUB_LOCATIONS_LIST_RECEIVED:
            return {
                ...state,
                clubLocationsHash: {
                    ...state.clubLocationsHash,
                    ..._keyBy(action.clubLocationsList, 'id'),
                },
            };
        case actionTypes.CLUB_LOCATION_REMOVE:
            return {
                ...state,
                clubLocationsHash: {
                    ..._omit(state.clubLocationsHash, action.clubLocationId),
                },
            };
        case actionTypes.CLUB_LOCATION_TYPES_RECEIVED:
            return {
                ...state,
                clubLocationTypesHash: {
                    ...state.clubLocationTypesHash,
                    [action.organizationId]: action.locationTypesMetadata,
                },
            };
        default:
            return state;
    }
};
