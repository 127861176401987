import { useEffect } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import Organization from './Organization';
import { organizationSelector, organizationStorageInfoSelector } from './organizations.selectors';
import { organizationDataRequest, organizationStorageInfoRequest } from './organizations.actions';

export const useOrganization = (organizationId: string) => {
    const dispatch = useDispatch();
    const organization = useSelector((state: RootState) =>
        organizationSelector(state, { organizationId }),
    ) as Organization | undefined;

    useEffect(() => {
        if (!organization) {
            dispatch(organizationDataRequest({ organizationId }));
        }
    }, [organization]);

    return organization;
};

export const useOrgStorageInfo = (organizationId: string) => {
    const dispatch = useDispatch();
    const storageInfo = useSelector((state: RootState) =>
        organizationStorageInfoSelector(state, { organizationId }),
    );

    useEffect(() => {
        if (!storageInfo) {
            dispatch(organizationStorageInfoRequest({ organizationId }));
        }
    }, [storageInfo]);

    return storageInfo;
};
