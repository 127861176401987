export default {
    CREATE_CLUB_LOCATION_REQUEST: 'ENTITIES/CLUB_LOCATIONS/CREATE_CLUB_LOCATION_REQUEST',
    UPDATE_CLUB_LOCATION_REQUEST: 'ENTITIES/CLUB_LOCATIONS/UPDATE_CLUB_LOCATION_REQUEST',
    CLUB_LOCATION_RECEIVED: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_RECEIVED',
    CLUB_LOCATIONS_LIST_RECEIVED: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATIONS_LIST_RECEIVED',
    CLUB_LOCATIONS_LIST_REQUEST: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATIONS_LIST_REQUEST',
    CLUB_LOCATION_REQUEST: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_REQUEST',
    CLUB_LOCATION_DELETE_REQUEST: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_DELETE_REQUEST',
    CLUB_LOCATION_REMOVE: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_REMOVE',
    CLUB_LOCATION_TYPES_REQUEST: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_TYPES_REQUEST',
    CLUB_LOCATION_TYPES_RECEIVED: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_TYPES_RECEIVED',
    CLUB_LOCATION_TYPES_UPDATE: 'ENTITIES/CLUB_LOCATIONS/CLUB_LOCATION_TYPES_UPDATE',
};
