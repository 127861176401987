import { StorageInfo } from 'entities/storage-files/StorageFile';
import actionTypes from './organizations.actionTypes';
import Organization, {
    OrganizationData,
} from './Organization';

export const organizationDataRequest = ({ organizationId }: { organizationId: string }) => ({
    type: actionTypes.ORGANIZATION_DATA_REQUEST,
    organizationId,
});

export const organizationsListRequest = (count?: number) => ({
    type: actionTypes.ORGANIZATIONS_LIST_REQUEST,
    count,
});

export const organizationDataRecieved = ({
    organizationData,
    organizationId,
}: {
    organizationId: string;
    organizationData: Organization;
}) => ({
    type: actionTypes.ORGANIZATION_DATA_RECIEVED,
    organizationData,
    organizationId,
});

export const organizationStorageInfoRecieved = ({
    orgStorageInfo,
    organizationId,
}: {
    organizationId: string;
    orgStorageInfo: StorageInfo;
}) => ({
    type: actionTypes.ORGANIZATION_STORAGE_INFO_RECIEVED,
    orgStorageInfo,
    organizationId,
});

export const organizationStorageInfoUpdate = ({
    orgStorageInfo,
    organizationId,
}: {
    organizationId: string;
    orgStorageInfo: StorageInfo;
}) => ({
    type: actionTypes.ORGANIZATION_STORAGE_INFO_UPDATE,
    orgStorageInfo,
    organizationId,
});

export const organizationStorageInfoRequest = ({ organizationId }: { organizationId: string }) => ({
    type: actionTypes.ORGANIZATION_STORAGE_INFO_REQUEST,
    organizationId,
});

export const organizationPlayersInfoRecieved = ({
    organizationPlayersInfo,
    organizationId,
}: {
    organizationId: string;
    organizationPlayersInfo: Record<string, string>;
}) => ({
    type: actionTypes.ORGANIZATION_PLAYERS_INFO_RECEIVED,
    organizationPlayersInfo,
    organizationId,
});

export const organizationPlayersInfoRequest = ({
    organizationId,
    onSuccess,
    onFail,
}: {
    organizationId: string;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.ORGANIZATION_PLAYERS_INFO_REQUEST,
    organizationId,
    onSuccess,
    onFail,
});

export const organizationClubsInfoRequest = ({
    organizationId,
    onSuccess,
    onFail,
}: {
    organizationId: string;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.ORGANIZATION_CLUBS_INFO_REQUEST,
    organizationId,
    onSuccess,
    onFail,
});

export const organizationClubsInfoRecieved = ({
    organizationClubsInfo,
    organizationId,
}: {
    organizationId: string;
    organizationClubsInfo: Record<string, string>;
}) => ({
    type: actionTypes.ORGANIZATION_CLUBS_INFO_RECEIVED,
    organizationClubsInfo,
    organizationId,
});

export const organizationsListRecieved = ({
    organizationsList,
}: {
    organizationsList: Organization[];
}) => ({
    type: actionTypes.ORGANIZATIONS_LIST_RECIEVED,
    organizationsList,
});

export const organizationDataUpdate = ({
    organizationData,
    organizationId,
}: {
    organizationData: OrganizationData;
    organizationId: string;
}) => ({
    type: actionTypes.ORGANIZATION_DATA_UPDATE,
    organizationData,
    organizationId,
});

export const updateOrgLogo = ({
    file,
    organizationId,
}: {
    file: File;
    organizationId: string;
}) => ({
    type: actionTypes.UPDATE_ORGANIZATION_LOGO,
    file,
    organizationId,
});